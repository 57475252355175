@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  font-family: Raleway;
}
.like {
  background:  none;
  border: none;
  cursor:pointer;
}

.like:hover{
  transform: scale(2);
}
.App {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Raleway';
}

.foundation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.foundation-box { 
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;
  margin: 10px;
}

.box { 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  height: auto;
  width: 1000px;
  line-height: 1;
  font-weight: 300;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.2s ease-in-out;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 20px;
}

.box:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}


.foundation-img {
  width: 50px;
  height: 50px;
}

.product-img {
  height: 150px;
  width: 150px;
}

.color {
  width: 50px;
  height: 50px;
  border-radius: 70px;
}

li {
  padding: 5px;
  margin: 5px;

}

.logo {
  height: 50px;
}

